<template>
<div class="recommend_list">
  <div class="inner">
    <div class="top_info">
      <div class="filter_area">
        <div class="filter_item" @click="setType(1)" :class="{filter_item_cur:param.type === 1}">推荐</div>
<!--        <div class="filter_item" @click="setType(2)" :class="{filter_item_cur:param.type === 2}">最新</div>-->
        <div class="search_box">
          <el-input v-model="param.query" placeholder="请输入您要查询的内容"/>
          <el-button @click="setKeyword" type="primary">搜索</el-button>
          <el-button @click="inviteSelected" type="primary">邀请所选</el-button>
        </div>
      </div>
      <div class="account_info">
<!--        账号8977492的<span style="color:#00b0b1;">高级Ui设计师</span>职位 <img class="triangle" src="../../assets/img/icon_triangle.png"/>-->
        <el-select v-model="param.companyPostId" @change="postChange">
          <el-option
              v-for="item in jobList"
              :label="item.postName"
              :key="item.id"
              :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="top_info" style="display:block;padding-top:0">
      <div class="filter_con">
        <div class="filter_item" @click="changeSelect('workExperienceId')" :class="{filter_item_cur:filterCon.workExperienceId!==''}">工作经验</div>
        <div class="filter_item" @click="changeSelect('education')" :class="{filter_item_cur:filterCon.education!==''}">学历</div>
        <div class="filter_item"  @click="changeSelect('cityCode')" :class="{filter_item_cur:filterCon.cityCode!==''}">城市</div>
      </div>

    </div>
<!--    <div class="top_info" style="padding-top:0;display:none">-->
<!--      <el-form inline style="padding:0">-->
<!--        <el-form-item label="学历">-->
<!--          <el-select v-model="param.education" @change="changeSelect('education')">-->
<!--            <el-option-->
<!--                v-for="item in userStore.degree"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="工作年限">-->
<!--          <el-select v-model="param.workExperienceLow" @change="changeSelect('work_experience')">-->
<!--            <el-option v-for="item in experienceList"-->
<!--                       :label="item.description"-->
<!--                       :key="item.id"-->
<!--                       :value="item.id"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="城市">-->
<!--          <el-cascader-->
<!--              style="width:100%;"-->
<!--              @change="changeSelect('city')"-->
<!--              :props="areaProp" v-model="cityCode"></el-cascader>-->
<!--        </el-form-item>-->

<!--      </el-form>-->
<!--    </div>-->
    <div class="list_info">
      <div class="info" :key="item.id" v-for="(item,index) in list">
        <div class="check_wrapper">
          <el-checkbox size="large" v-model="item.status" />
        </div>
        <div class="img_wrapper">
          <img :src="item.avatarUrl"/>
        </div>
        <div class="info_wrapper">
          <div class="info_top">
            <div class="name">{{item.realName}}</div>
            <div class="expr">{{item.workYear}}年‧{{userStore.getDegree(item.education)}}</div>
          </div>
          <div class="info_brief">
            期望：<span>{{expectStr(item.expectPostList)}}</span>
            优势：<span>{{item.advantage}}</span>
          </div>
          <div class="info_list">
            <div class="info_f" v-for="(ele,eleIndex) in item.userWorkExperienceList" :key="eleIndex">
              <div class="f_date">{{ele.startDate}} ~ {{ele.endDate}}</div>
              <div class="f_title">{{ele.companyName}} <div class="f_pos">{{ele.post}}</div></div>
            </div>
          </div>
        </div>
        <el-button type="primary" @click="inviteJob(index)">邀请应聘</el-button>
      </div>

    </div>

    <el-pagination background layout="prev, pager, next"
                   :page-size="param.pageSize"
                   v-model:current-page="param.pageNum"
                   @current-page="pageChange"
                   :total="total" />

  </div>
</div>
</template>

<script>
import { getJobList,getApplyUserRecommendList,inviteUserApply,jobSelectList,getWorkExperienceList } from "@/api/company/company"
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {getAreaList, getCityList, getProvinceList} from "@/api/user/area";
export default {
  name: "recommendList",
  data(){
    return {
      list:[],
      jobList:[],
      total:0,
      filterCon:{
        workExperienceId:"",
        education:"",
        cityCode:""
      },
      selectedJob:{
        workExperienceId: "",
        education:"",
        cityCode:"",
        provinceCode: ""
      },
      param:{
        education:"",
        cityCode:"",
        provinceCode:"",
        workExperienceLow:"",
        "companyPostId": "",
        "pageNum": 1,
        "pageSize": 10,
        "query": "",
        // "type": 1
      },
      experienceList:[],
      cityCode:[],
      areaProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            getProvinceList().then(res => {
              res.data.forEach((v)=>{
                nodes.push({
                  label:v.provinceName,
                  value:v.provinceCode,
                  leaf:false
                });
              })
              resolve(nodes);
            });
          }else if(level === 1){
            getCityList({
              provinceCode:node.data.value
            }).then(res => {
              res.data.forEach(v => {
                nodes.push({
                  label:v.cityName,
                  value:v.cityCode,
                  leaf:true
                });
              })
              resolve(nodes);
            });
          }
          // else if(level === 2){
          //   getAreaList({
          //     cityCode:node.data.value
          //   }).then(res => {
          //     res.data.forEach(v => {
          //       nodes.push({
          //         label:v.areaName,
          //         value:v.areaCode,
          //         leaf:true
          //       });
          //     });
          //     resolve(nodes);
          //   });
          // }
        }
      },
    }
  },
  methods:{
    changeSelect(act){
      if(this.filterCon[act] !==""){
        this.filterCon[act] = "";
      }else{
        this.filterCon[act] = 1;
      }
      this.resetFilterCon();
      // if(this.filterCon[act] !== ""){
      //   this.filterCon[act] = "";
      //
      //   if(act === "education"){
      //     this.param.education = "";
      //   }else if(act === "workExperienceId"){
      //     this.param.workExperienceLow = "";
      //   }else if(act === "cityCode"){
      //     this.param.cityCode = "";
      //     this.param.provinceCode = "";
      //   }
      // }else if(Object.keys(this.selectedJob).length > 0){
      //   this.filterCon[act] = 1;
      //   if(act === "education"){
      //     this.param.education = this.selectedJob.education;
      //   }else if(act === "workExperienceId"){
      //     this.param.workExperienceLow = this.selectedJob.workExperienceId;
      //   }else if(act === "cityCode"){
      //     this.param.cityCode = this.selectedJob.cityCode;
      //     this.param.provinceCode = this.selectedJob.provinceCode;
      //   }
      // }
      // this.getApplyUserRecommendList();
    },
    resetFilterCon(){
      this.param.pageNum = 1;
      this.param.education = "";
      this.param.workExperienceLow = "";
      this.param.cityCode = "";
      this.param.provinceCode = "";

      if(this.filterCon.education === 1){
        this.param.education = this.selectedJob.education;
      }
      if(this.filterCon.workExperienceId === 1){
        this.param.workExperienceLow = this.selectedJob.workExperienceId;
      }
      if(this.filterCon.cityCode === 1){
        this.param.provinceCode = this.selectedJob.provinceCode;
        this.param.cityCode = this.selectedJob.cityCode;
      }
      this.getApplyUserRecommendList();
    },
    expectStr(list){
      let expectList = [];
      for(let i in list){
        expectList.push(list[i].postClassificationName);
      }
      return expectList.join(",")
    },
    setType(t){
      if(this.param.type === t){
        return ;
      }
      this.param.type = t;
      this.param.pageNum = 1;
      this.getApplyUserRecommendList();
    },
    setKeyword(){
      this.param.pageNum = 1;
      this.getApplyUserRecommendList();
    },
    pageChange(v){
      this.param.pageNum = v;
      this.getApplyUserRecommendList();
    },
    postChange(v){
      this.param.companyPostId = v;
      this.param.pageNum = 1;
      this.getSelectJob();
    },
    getSelectJob(){
      for(let i in this.jobList){
        if(this.jobList[i].id === this.param.companyPostId){
          this.selectedJob = this.jobList[i];
          break;
        }
      }
      this.resetFilterCon();
    },
    inviteJob(index){
      this.inviteUserApply([this.list[index].userId]);
    },
    inviteUserApply(userIdList){
      inviteUserApply({
        companyPostId:this.param.companyPostId,
        userIdList:userIdList
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"已发送邀请"
          });
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    },
    inviteSelected(){
      let userIdList = [];
      for(let i in this.list){
        if(this.list[i].status){
          userIdList.push(this.list[i].userId);
        }
      }
      if(userIdList.length === 0){
        return;
      }
      this.inviteUserApply(userIdList);
    },
    getApplyUserRecommendList(){
      if(this.param.companyPostId === ""){
        console.log("职位id不能为空");
        return ;
      }
      getApplyUserRecommendList(this.param).then(res => {
        if(res.code === 0){
          for(let i in res.data.rows){
            res.data.rows[i].status = false;
          }
          this.list = res.data.rows;
          this.total = res.data.total;
        }
      });
    }
  },
  mounted(){

    // getWorkExperienceList().then(res => {
    //   if(res.code === 0){
    //     this.experienceList = res.data;
    //   }
    // });
    jobSelectList().then(res => {
      if(res.code === 0){
        this.jobList = res.data;
        if(this.jobList.length > 0){
          if(this.param.companyPostId === ""){
            this.param.companyPostId = this.jobList[0].id;
            // this.selectedJob = this.jobList[0];
          }
          // this.getApplyUserRecommendList();
        }
        this.getSelectJob();
      }
    });
    if(this.$route.query.id !== undefined){
      this.param.companyPostId = this.$route.query.id - 0;
      this.getSelectJob();
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.recommend_list{
  padding-bottom:25px;
  .el-pagination{
    display: flex;
    margin-top:30px;
    justify-content: center;
  }
  .top_info{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 35px;
    .filter_con{
      display:flex;
      margin-bottom:30px;
      .filter_title{
        font-size: 14px;
        color: #1a1a1a;
        font-weight: bold;
        margin-right:35px;
      }
      .filter_item{
        font-size: 14px;
        margin-right:25px;
        cursor:pointer;
        color: #666666;
        border:1px solid #dcdcdc;
        border-radius:5px;
        width:100px;
        text-align: center;
        height:40px;line-height:40px;
      }
      .filter_item:hover{
        color:@blue_color;
      }
      .filter_item_cur{
        color:#fff!important;
        background-color:@blue_color
      }
    }
  }
  .account_info{
    font-size: 15px;
    color: #333333;
  }
  .el-form--inline .el-form-item{
    margin-bottom:10px;
  }
  .filter_area{
    display:flex;
    .filter_item{
      width:100px;
      cursor:pointer;
      align-items: center;
      display:flex;
    }
    .filter_item:hover,.filter_item_cur{
      color:@blue_color;
    }
    .el-input{
      margin-right:12px;
    }
  }
  .list_info{
    margin-top:25px;
    .info{
      display:flex;
      padding:35px;
      margin-bottom:10px;
      background-color:#fff;
      .el-checkbox{
        margin-top:20px;
      }
      .el-button--primary{
        margin-top:30px;
      }
      .info_wrapper{
        flex:1;
        .info_list{
          margin-top:25px;
          display:flex;
          .info_f{
            margin-right:40px;
            color:#1a1a1a;
            &:last-child{
              margin-right:0;
            }
            .f_date{
              font-size:16px;
            }
            .f_title{
              font-size:15px;
              font-weight: bold;
              margin-top:5px;
              display:flex;
              align-items: center;
              .f_pos{
                font-weight: normal;
                margin-left:5px;
              }
            }
          }
        }
        .info_brief{
          font-size: 16px;
          margin-top:5px;
          color: #1a1a1a;
          span{
            opacity: .7;
            font-size:15px;
            margin-right:10px;
          }
        }
        .info_top{
          display:flex;
          margin-top:5px;
          align-items: flex-end;
          .name{
            color:@blue_color;
            font-size:20px;
          }
          .expr{
            font-size: 16px;
            color: #1a1a1a;
            margin-left:15px;
          }
        }
      }
    }
    .img_wrapper{
      margin-left:20px;
      width:90px;
      height:90px;
      margin-right:5px;
      img{
        width:100%;
        height:100%;
        border-radius: 90px;
      }
    }

  }
}

</style>
